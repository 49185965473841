
import axios from "axios";
const BASE_URI = process.env.REACT_APP_BASE_URI;

const headersMultiPartForm = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

const headersAppJson = {
    headers: {
        "Content-Type": "application/json",
    },
};

const headersAppLd = {
    headers: {
        "Content-Type": "application/ld+json",
    },
};

export const convertToSvgFile = async (datas) => {
    try {
        const url = BASE_URI + "/conversion/tosvg";
        const response = await axios.post(url, datas, {
            responseType: 'blob', // Indiquez que la réponse est un objet binaire (blob)
        });

        // const response = await axios.post(url, datas, headersMultiPartForm);
        const result = URL.createObjectURL(response.data);
        //const result = response.data;
        // return { response: "success", urlOriginalFile: result.urlOriginalFile , urlVectoriseFile: result.urlVectoriseFile };
        return { response: "success", urlVectoriseFile: result };
        // const newTab = window.open(urlFile, "_blank");
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return { status: false, error: message };
    }
};

export const convertAiToFile = async (datas) => {
    try {
        const url = BASE_URI + "/conversion/fromai";
        const response = await axios.post(url, datas, {
            responseType: 'blob', // Indiquez que la réponse est un objet binaire (blob)
        });

        // const response = await axios.post(url, datas, headersMultiPartForm);
        const result = URL.createObjectURL(response.data);
        //const result = response.data;
        // return { response: "success", urlOriginalFile: result.urlOriginalFile , urlVectoriseFile: result.urlVectoriseFile };
        return { response: "success", urlFile: result };
        // const newTab = window.open(urlFile, "_blank");
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        return { status: false, error: message };
    }
};
