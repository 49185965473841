import '../App.css';
import {
    Box,
    Button,
    Chip,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { blue } from "@mui/material/colors";
import { styled } from '@mui/material/styles';
import {convertAiToFile, convertToSvgFile} from "../Service/ConversionService";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

var activeFileAi = false;
var activeFileFromAi = false;
const Home = ()=>{

    const [theFile, setTheFile] = useState();
    const [loadFile, setLoadFile] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [activeImageFile, setActiveImageFile] = useState(null);
    const [originalFile, setOriginalFile] = useState(null);
    const [isImage1Visible, setIsImage1Visible] = useState(true);
    const scaleStep = 0.1; // Pas d'échelle pour le zoom

    const [scale, setScale] = useState(1);
    const [posX, setPosX] = useState(0);
    const [posY, setPosY] = useState(0);
    const ZOOM_SPEED = 0.05;
    const [isMoving, setIsMoving] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [initialOffsetX, setInitialOffsetX] = useState(0);
    const [initialOffsetY, setInitialOffsetY] = useState(0);
    const [optionsFile, setOptionsFile] = useState([
        {value: "png", label: "PNG"},
        {value: "pdf", label: "PDF"},
        {value: "jpeg", label: "JPG"},
        {value: "webp", label: "WEBP"},
        {value: "tiff", label: "TIFF"},
        {value: "bmp", label: "BMP"},
        {value: "gif", label: "GIF"}
    ]);
    const [outputFile, setOutputFile] = useState("");
    const [fileFromAi, setFileFromAi] = useState(null);

    useEffect(() => {
        const handleKeyPress = (event) => {
            console.log("Active file: ", activeFileAi);
            if (activeFileAi){
                console.log("Here in:");
                if (event.key === 'b' || event.key === 'B') {
                    // Appeler votre fonction ici
                    toggleImage();
                }
                if (event.key === 'i' || event.key === 'I') {
                    resetTransform();
                }
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const handleChangeSelectedOutputFile = async (event)=> {
        setOutputFile(event.target.value);
    }

    const updateTransform = () => {
        /*const imgWrapper1 = document.getElementById('imgWrapper1');
        if (imgWrapper1) {
            imgWrapper1.style.transform = `translate(${posX}px, ${posY}px) scale(${scale})`;
            console.log("Transform");
        }*/
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        console.log("Here in move:");
        setIsMoving(true);
        setStartX(e.clientX);
        setStartY(e.clientY);
        setInitialOffsetX(offsetX);
        setInitialOffsetY(offsetY);
    };

    const handleMouseMove = (e) => {
        if (isMoving) {

            /*const newOffsetX = e.clientX - startX;
            const newOffsetY = e.clientY - startY;*/

            const newOffsetX = initialOffsetX + (e.clientX - startX);
            const newOffsetY = initialOffsetY + (e.clientY - startY);

            setOffsetX(newOffsetX);
            setOffsetY(newOffsetY);
        }
    };

    const handleMouseUp = () => {
        setIsMoving(false);
    };

    const resetTransform = () => {
        setScale(1);
        setOffsetX(0);
        setOffsetY(0);
    };

    /*const handleMouseDown = (e) => {
        e.preventDefault();
        const container = document.getElementById('container');
        container.style.cursor = 'grabbing';
        let startX = e.clientX;
        let startY = e.clientY;
        console.log("startX: ", startX);
        console.log("startY: ", startY);

        const onMouseMove = (e) => {
            console.log("PosX: ", posX);
            console.log("PosY: ", posY);

            setPosX(posX + (e.clientX - startX));
            setPosY(posY + (e.clientY - startY));
            startX = e.clientX;
            startY = e.clientY;
            // updateTransform();
        };

        const onMouseUp = () => {
            container.style.cursor = 'grab';
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };*/

    const handleWheel = (e) => {
        //e.preventDefault();
        if (e.cancelable) {
            e.preventDefault();
        }
        setScale((prevScale) => {
            let newScale = prevScale + (e.deltaY > 0 ? -ZOOM_SPEED : ZOOM_SPEED);
            newScale = Math.max(newScale, 0.1);
            return newScale;
        });
        // updateTransform();
    };

    const handleChangeFile = (event)=>{
        var file = event.target.files[0];
        setSelectedFile(file.name);
        const urlOriginalFile = URL.createObjectURL(file);
        setOriginalFile(urlOriginalFile);
        setTheFile(file);
        activeFileAi = false;
        activeFileFromAi = false;
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleUploadImage = async ()=>{

        if (activeFileAi || activeFileFromAi){
            // Créer un élément <a> pour déclencher le téléchargement
            const link = document.createElement('a');
            var fileName = theFile.name;
            var fileSplit = fileName.split(".");
            var partName = fileSplit[0];
            if (activeFileAi){
                link.href = activeImageFile;
                link.download = partName + ".svg"; // Nom du fichier à télécharger
            }

            if (activeFileFromAi){
                link.href = fileFromAi;
                link.download = partName + "." + outputFile; // Nom du fichier à télécharger
            }

            // Ajouter l'élément <a> au document
            document.body.appendChild(link);
            // Cliquer sur le lien pour déclencher le téléchargement
            link.click();
            // Nettoyer l'URL du blob après le téléchargement
            if (activeFileAi){
                window.URL.revokeObjectURL(activeImageFile);
            }
            if (activeFileFromAi){
                window.URL.revokeObjectURL(fileFromAi);
            }
        }else {
            const formData = new FormData();
            if (theFile.type === 'image/jpeg' ||       // JPEG image
                theFile.type === 'image/png'           // PNG image
            ) {
                formData.append('theFile', theFile);
                setLoadFile(true);
                var res = await convertToSvgFile(formData);
                setLoadFile(false);
                if (res.response === "success"){
                    setActiveImageFile(res.urlVectoriseFile);
                    activeFileAi = true;
                    activeFileFromAi = false;
                    /*setUrlVectoriseFile(res.urlVectoriseFile);
                    setUrlOriginalFile(res.urlOriginalFile);*/
                }
            }else {
                if (theFile.type === "application/postscript" || theFile.type === "image/svg+xml"
                ) {

                    formData.append('theFile', theFile);
                    formData.append('outPutFile', outputFile);
                    setLoadFile(true);
                    var res = await convertAiToFile(formData);
                    setLoadFile(false);
                    if (res.response === "success"){

                        setFileFromAi(res.urlFile);
                        activeFileAi = false;
                        activeFileFromAi = true;

                        // *setUrlVectoriseFile(res.urlVectoriseFile);
                        // setUrlOriginalFile(res.urlOriginalFile);
                    }
                }else {
                    alert("Type du fichier glissé incorrect.");
                    console.error("ERROR FILETYPE: ", theFile.type);
                }
            }
        }
    }

    const toggleImage = () => {
        setIsImage1Visible(prevState => !prevState);
    };

    return (
       <Container >

           <Typography variant="h5" gutterBottom style={{marginTop:"30px", marginBottom:"30px"}}>
               Convertir des image au FORMAT  png , jpeg => svg  |  ai => pdf , jpg , png, gif, bmp, tiff, webp
           </Typography>
           <Grid container>
                    <Grid item sm={12}>
                        <Stack direction={"column"}>
                            <div className="custom-upload" id="customUpload"
                                 style= {{display:"flex", justifyContent:"center", alignItems:"center"}}
                                 onDragOver={(e) => e.preventDefault()}
                                 onDrop= {(e) => {}}
                            >
                                <label htmlFor="uploadInput">
                                    <Button
                                        style={{color:"#fff"}}
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<AddIcon />}
                                    >
                                        Ajouter un logo
                                        <VisuallyHiddenInput type="file" onChange={handleChangeFile} />
                                    </Button>
                                </label>
                            </div>
                            <div style={{textAlign:"start", marginTop:"10px", marginBottom:"10px"}}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Fichier séléctionné: {selectedFile ? <Chip label={selectedFile} variant="outlined" /> : null}
                                </Typography>
                            </div>

                            <Grid container columns={12}>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Fichier de sortie
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="outputFile"
                                            label="Fichier de sortie"
                                            value={outputFile}
                                            onChange={handleChangeSelectedOutputFile}
                                        >
                                            {optionsFile.map(
                                                (item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={item["value"]}
                                                    >
                                                        {item.label}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <div style={{textAlign:"center"}}>

                                { activeFileAi
                                    ?
                                    <>
                                        <div className="zoomable-image-container" style= {{
                                            position:"relative",
                                            width: "100",
                                            height: "500px",
                                            backgroundImage: "url('/media/images/damier_front.jpg')",
                                            backgroundSize: "cover",
                                            borderRadius: "5px",
                                            overflow: "hidden",
                                        }}
                                        >
                                            <Typography variant="subtitle2" gutterBottom className={"absolutely-positioned"}>
                                                {isImage1Visible ? "Image vectorisé" : "Image originale"}
                                            </Typography>
                                            <div
                                                style={{
                                                    margin:"auto",
                                                    position: 'relative',
                                                    width: '800px',
                                                    height: '800px',
                                                }}
                                                onMouseMove={handleMouseMove}
                                                onMouseUp={handleMouseUp}
                                                onWheel={handleWheel}
                                            >
                                                { activeFileAi &&
                                                    <img
                                                    src={isImage1Visible ? activeImageFile : originalFile}
                                                    alt="Your image"
                                                    style={{
                                                        position: 'absolute',
                                                        left: `${offsetX}px`,
                                                        top: `${offsetY}px`,
                                                        width: `${scale * 100}%`,
                                                        height: 'auto',
                                                        cursor: isMoving ? 'grabbing' : 'grab',
                                                    }}
                                                    onMouseDown={handleMouseDown}
                                                    draggable="false"
                                                    />
                                                }
                                            </div>

                                        </div>
                                    </>
                                    : null
                                }

                                { activeFileFromAi &&
                                    <>
                                        <p>Fichier converti :</p>
                                        <img src={fileFromAi} alt="Fichier converti" style={{ maxWidth: '100%' }} />
                                    </>
                                }

                            </div>
                        </Stack>
                    </Grid>
                    <Grid item sm={12}>
                        {theFile &&
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button
                                    variant="contained"
                                    disabled={loadFile}
                                    onClick={(e)=> handleUploadImage(e)}
                                >
                                    {activeFileAi || activeFileFromAi ? "Télécharger" : "Convertir"}
                                </Button>
                                {loadFile && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: blue[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        }
                    </Grid>
                </Grid>
       </Container>
    );
}
export default Home;
