import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from "@mui/material/Stack";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Hidden from '@mui/material/Hidden';
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import {Container, Grid} from "@mui/material";
import Box from "@mui/material/Box";

const HamburgerMenu = () => {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    // const navigate = useNavigate();
    const handleClick = () => {
        // navigate("/imprimantes/config");
    };

    const buttonConfig = <button
            className={"btn-imprimante"}
            onClick={handleClick}
        >
        <PermDataSettingIcon/> 
    </button>

    return (
        <div>
            <div className={"box-header"} style={{ padding:"0 20px",display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                <div>
                    <h2>DTF</h2>
                </div>
                <div>
                    {/* Utilise Hidden pour masquer le bouton sur les grands écrans */}
                    <Hidden mdDown>
                        {buttonConfig}
                    </Hidden>

                    <Hidden mdUp>
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleDrawerOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                </div>
                <Drawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={handleDrawerClose}
                >
                    {/* Contenu du menu va ici */}
                    <div style={{ width: 250, padding:"5px 2px" }} >
                        <Grid container spacing={2} alignItems={"center"} justifyContent={"start"}>
                            <Grid item xs={12}>
                                {buttonConfig}
                            </Grid>
                        </Grid>
                    </div>
                </Drawer>
            </div>
        </div>
    );
};

export default HamburgerMenu;
